import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import cx from 'classnames'

import { trackUserEvent } from 'actions/telemetry_actions_v2'
import { getCurrentKaseId } from 'reducers/selectors'
import formatMoney from 'lib/format_money'

import ArrowCircleIcon from 'components/icons/arrow_circle_icon'
import ControlledAccordion from 'components/accordion/controlled_accordion'
import Error from 'components/error'
import GreenCircleCheckmarkListIcon from 'components/icons/green_circle_checkmark_list_icon'
import GreenCardSample from 'images/dashboard/milestone_tracker_green_card_sample.png'
import PostMilestoneTask from './postMilestoneTask'
import PreMilestoneTaskList from './preMilestoneTaskList'

import { TELEMETRY_EVENTS } from 'lib/constants'
import { MilestoneType } from '../post_ship_dashboard/lib/types'
import Button from 'components/button'
import ArrowIcon from 'components/icons/arrow_icon'

interface ActionProps {
  /**
   * Action that allows us to send tracking events to the backend so they can
   * send them on the Segment, ad blockers can't block the events sent to BE first :D
   */
  trackUserEvent: typeof trackUserEvent
}

interface InjectedProps {
  /**
   * The key for the currently opened milestone
   */
  currentMilestoneKey: string
  /**
   * This is an error thrown after there was an issue when we hit an endpoint
   */
  error?: string
  /**
   * The index where this current Milestone component fits within the MilestoneList
   */
  index: number
  /**
   * Boolean used for mobile styling
   */
  isMobile: boolean
  /**
   * Boolean from the reducer when the request to update a Milestone
   * has been made and before it is complete
   */
  isUpdating: boolean
  /**
   * The last milestone of the last milestone group in the array of milestones in the array of
   * milestone groups, provided by chained array methods finding this string
   */
  lastMilestoneKey: string
  /**
   * The milestone data object
   */
  milestone: MilestoneType
  /**
   * If there is a next milestone in the array of Milestones, its key will be provided so we
   * can auto expand its pretasks when the current milestone is completed
   */
  nextMilestoneKey?: string
  /**
   * Function that saves the milestone answer, posting it to the backend and updating the redux store
   */
  onSaveMilestoneAnswer: (answer: string, milestoneTaskId: number, milestoneTaskKey: string, answerId?: number) => void
  /**
   * Sets the new current milestone key by passing in the milestone key of the one just clicked
   */
  setCurrentMilestoneKey: (newCurrentMilestoneKey: string) => void
}

interface MappedProps {
  kaseId: number
  potentialDiscountAmountInCents: number
}

type Props = InjectedProps & ActionProps & MappedProps

const Milestone: FunctionComponent<Props> = ({
  currentMilestoneKey,
  error,
  index,
  isMobile,
  isUpdating,
  kaseId,
  lastMilestoneKey,
  milestone,
  nextMilestoneKey,
  onSaveMilestoneAnswer,
  potentialDiscountAmountInCents,
  setCurrentMilestoneKey,
  trackUserEvent
}) => {
  const { key, completed, name, display_date, milestone_tasks } = milestone
  const [showCompletedPreTasks, setShowCompletedPreTasks] = useState(false)
  const [isCurrentMilestoneVisible, setIsCurrentMilestoneVisible] = useState(true)

  const isCurrentMilestone = currentMilestoneKey === key
  const isLastMilestone = currentMilestoneKey === lastMilestoneKey

  const postMilestoneTasks = milestone_tasks.filter((task) => task.type === 'PostMilestoneTask')
  const preMilestoneTasks = milestone_tasks.filter((task) => task.type === 'PreMilestoneTask')

  const preTasksCompleted = preMilestoneTasks.filter((task) => !task.completed).length === 0
  const postTasksCompleted = postMilestoneTasks.filter((task) => !task.completed).length === 0

  const isK1ConversionFinalMilestone = isLastMilestone
  const [showButton, setShowButton] = useState(true)
  const [disabledField, setDisabledField] = useState(false)

  useEffect(() => {
    if ((postTasksCompleted || !postMilestoneTasks.length) && nextMilestoneKey) {
      setCurrentMilestoneKey(nextMilestoneKey)
    }
  }, [preTasksCompleted, postTasksCompleted])

  useEffect(() => {
    if (currentMilestoneKey && currentMilestoneKey !== 'mail_application') {
      const observer = new IntersectionObserver(
        (entries) => {
          entries[0].isIntersecting ? setIsCurrentMilestoneVisible(true) : setIsCurrentMilestoneVisible(false)
          observer.disconnect()
        },
        { threshold: 1 }
      )

      observer.observe(document.getElementById(currentMilestoneKey))
    }

    if (currentMilestoneKey === 'mail_application') {
      const completedMilestones = preMilestoneTasks.filter(
        (task) => task.completed && task.key === 'i-129f_filing_date'
      )

      if (completedMilestones.length > 0) {
        setShowButton(false)
        setDisabledField(true)
      }
    }
  }, [currentMilestoneKey])

  useEffect(() => {
    const offset = document.getElementById('app-navbar').offsetHeight + 60

    if (!isCurrentMilestoneVisible) {
      scroller.scrollTo(currentMilestoneKey, {
        offset: -offset
      })
    }
  }, [isCurrentMilestoneVisible])

  const getShowHideButtonCopy = () => {
    if (showCompletedPreTasks) {
      if (postMilestoneTasks.length && !isK1ConversionFinalMilestone) {
        return 'View Tasks'
      } else {
        return 'Hide'
      }
    } else if (currentMilestoneKey === 'marriage') {
      return 'Edit marriage info'
    } else {
      return 'View my info'
    }
  }

  const handleListItemClick = (key: string) => {
    // if the user clicks on the milestone that is already expanded open (as
    // the "current milestone") then clear the current milestone key to be empty
    // thus collapsing the expanded current milestone.
    if (key === currentMilestoneKey) {
      setCurrentMilestoneKey('')
    } else {
      setCurrentMilestoneKey(key)

      trackUserEvent(TELEMETRY_EVENTS.CLICKED_POST_SHIP_DASHBOARD_MILESTONE, {
        milestoneKey: key
      })
    }
  }

  const header = (
    <span
      className={cx('flex', {
        'items-center justify-between': !isMobile,
        'flex-col': isMobile,
        'mt-1': isCurrentMilestone
      })}
    >
      <span
        className={cx('c-type', {
          'c-type--headline-sm text-black': isCurrentMilestone && !completed,
          'c-type--headline-md': isMobile && isCurrentMilestone && !completed,
          'c-type--body-sans-md': isCurrentMilestone && completed
        })}
      >
        {!isCurrentMilestone && (
          <span
            className={cx('position-relative', {
              'list-style-incomplete': !completed,
              'list-style-complete': completed
            })}
          ></span>
        )}
        {isCurrentMilestone && !completed && <ArrowCircleIcon className="mr-2" arrowDirection="right" />}
        {isCurrentMilestone && completed && <span className="position-relative header-style-complete"></span>}
        {name}
      </span>
      <span
        className={cx('c-type c-type--body-sans-sm', {
          'text-right mr-2': !isMobile,
          'mt-1 mb-2': isMobile,
          'mt-3 ml-7': isMobile && isCurrentMilestone
        })}
      >
        {display_date}
      </span>
    </span>
  )

  return (
    <ControlledAccordion
      id={milestone.key}
      className={cx('border-x-0', { 'border-t-0': index > 0, 'border-0': isCurrentMilestone })}
      header={header}
      hasSummaryOutline={false}
      isOpen={currentMilestoneKey === key}
      onClicked={() => handleListItemClick(key)}
      noXPadding
    >
      {preTasksCompleted && isCurrentMilestone && (
        <div className="c-type c-type--body-sans-sm c-task__edit md:text-right">
          <a
            className="u-clickable"
            onClick={() => setShowCompletedPreTasks(!showCompletedPreTasks)}
            title={getShowHideButtonCopy()}
          >
            {getShowHideButtonCopy()}
          </a>
        </div>
      )}
      {!preTasksCompleted && milestone.description && (
        <h2 className="c-type c-type--body-sans-md pt-5 pb-3">{milestone.description}</h2>
      )}
      {error && <Error>{error}</Error>}
      {(!preTasksCompleted || showCompletedPreTasks) && (
        <PreMilestoneTaskList
          buttonCopy={milestone.completed_button_copy}
          isLastMilestone={isLastMilestone}
          isMobile={isMobile}
          isUpdating={isUpdating}
          onSaveMilestoneAnswer={(answer, milestoneTaskId, milestoneTaskKey, answerId) =>
            onSaveMilestoneAnswer(answer, milestoneTaskId, milestoneTaskKey, answerId)
          }
          preTasksCompleted={preTasksCompleted}
          preMilestoneTasks={preMilestoneTasks}
          showButton={showButton}
          disabledField={disabledField}
        />
      )}
      {postMilestoneTasks.length > 0 &&
        preTasksCompleted &&
        !showCompletedPreTasks &&
        !isK1ConversionFinalMilestone && (
          <div className={cx('border-b border-gray-200 mb-4 py-4', { 'ml-4': isMobile })}>
            <h3 className="c-type c-type--subhead-md">Get a head start on upcoming steps</h3>
          </div>
        )}
      {isK1ConversionFinalMilestone && (
        <div className={cx('border-b border-gray-200 mb-4 py-2', { 'ml-4': isMobile })} />
      )}
      {preTasksCompleted &&
        !showCompletedPreTasks &&
        !isK1ConversionFinalMilestone &&
        postMilestoneTasks.map((task) => (
          <PostMilestoneTask
            key={task.key}
            isMobile={isMobile}
            onSaveMilestoneAnswer={(answer, milestoneTaskId, milestoneTaskKey, answerId) =>
              onSaveMilestoneAnswer(answer, milestoneTaskId, milestoneTaskKey, answerId)
            }
            task={task}
          />
        ))}
      {preTasksCompleted && postMilestoneTasks.length > 0 && !showCompletedPreTasks && !isLastMilestone && (
        <p className="flex items-center py-4">
          <ArrowCircleIcon className="mr-2" />
          Once you reach the next step in the process below, click it to get started!
        </p>
      )}
      {preTasksCompleted && !showCompletedPreTasks && isLastMilestone && (
        <div
          className={cx('bg-blue-200 flex justify-around c-type', { 'p-8 ml-8 mt-8 mb-4': !isMobile, 'p-4': isMobile })}
        >
          <div className={cx('text-center', { 'p-2': isMobile })}>
            <h2 className="c-type--subhead-md mb-6">Your Green Card Wait Time</h2>
            <p className="c-type--headline-md text-black mb-6">~13-21 months</p>
            <p className="c-type--body-sans-sm">or sooner, depending on your situation</p>
          </div>
          <span className={cx('border-r border-gray-400', { 'mx-2': isMobile })} />
          <div className={cx('text-center', { 'p-2': isMobile })}>
            <h2 className="c-type c-type--subhead-md mb-6">Your Government Filing Fees</h2>
            <p className="c-type--headline-md text-black mb-6">$1,225</p>
            <p className="c-type--body-sans-sm">plus additional fees for applying children</p>
          </div>
        </div>
      )}
      {preTasksCompleted && !showCompletedPreTasks && isK1ConversionFinalMilestone && (
        <>
          <div className="o-griddle__row o-griddle__row--reverse@sm">
            <div className="o-griddle__col--12 o-griddle__col--sm-8">
              <p className="c-type--headline-md text-black py-8">
                You’ve already saved{' '}
                <span className="text-black italic c-type--headline-serif-md c-type--inline"> time and money </span> on
                your green card application.
              </p>
              <ul className="c-type--body-sans-md mb-5 o-list-bare">
                <li className="flex mb-3">
                  <GreenCircleCheckmarkListIcon className="mr-2" />
                  Transfer K-1 info to your new application and save hours of work
                </li>
                <li className="flex mb-3">
                  <GreenCircleCheckmarkListIcon className="mr-2" />
                  <div>
                    Get
                    <span className="text-success-500"> up to {formatMoney(potentialDiscountAmountInCents)} off </span>
                    your Boundless fee for sticking with us!
                  </div>
                </li>
              </ul>
            </div>
            <div className="o-griddle__col--12 o-griddle__col--sm-4">
              <img src={GreenCardSample} style={{ width: '100%', maxWidth: '250px', height: 'auto' }} />
            </div>
          </div>
          <div className={cx('mt-4 mb-2', { 'flex justify-start': !isMobile, 'pt-8': isMobile })}>
            <Button
              color="primary"
              className="o-block"
              size={isMobile ? 'small' : 'default'}
              onClick={() => {
                window.location.href = `/api/v1/kases/${kaseId}/continue_from_completed`
              }}
              label="Continue"
              icon={<ArrowIcon arrowDirection="right" />}
            />
          </div>
        </>
      )}
    </ControlledAccordion>
  )
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    trackUserEvent: (event: string, args) => dispatch(trackUserEvent(event, args))
  }
}

function mapStateToProps(state): MappedProps {
  return {
    kaseId: getCurrentKaseId(state),
    potentialDiscountAmountInCents: state.postShipDashboard.data.potential_discount_amount_in_cents
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(Milestone)
